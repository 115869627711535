import Label from "../../atoms/label";
import ColumnHeader from "./components/ColumnHeader";
import PatientNameBlock from "./components/patientNameBlock";
import ActionButton from "./components/tableComponents/ActionButton";
import CustomStatus from "./components/tableComponents/CustomStatus";
import ViewMore from "./components/tableComponents/ViewMore";

const PATIENT_INVITE_FIELDS =(handlePatientInviteResend, status)=> [{
    label : "status",
    field : CustomStatus,
},
{
    label : "user",
    field : (data)=> {
        return <PatientNameBlock {...data}/>
    },
},
{
    label:"viewDetails",
    field: ViewMore
},
{
    label : "action",
    field : (data)=> <ActionButton handlePatientInviteResend={handlePatientInviteResend} status={status} {...data}/>,
}
]

const PATIENT_INVITE_COLUMN_FIELDS=[{
        label : "phoneNumber",
        field : ColumnHeader,
    },
    {
        label : "user",
        field : ColumnHeader,
    },
    {
        label: "email",
        field: ColumnHeader
    },
    {
        label : "action",
        field : ColumnHeader,
    }
]

export {
    PATIENT_INVITE_FIELDS,
    PATIENT_INVITE_COLUMN_FIELDS
}