import styles from "./pageHeaderContext.module.css"

const PageHeaderContext = () => {

    return (
        
            <div className={styles.pageHeaderContext}>
                <div className={styles.subHeading}>Practice Management</div>
            </div>

    )
}

export default PageHeaderContext;