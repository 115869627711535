import styles from "./pagination.module.css"

const Pagination = ({currentPagination, handlePagincationAction}) => {
    const handleForwardAction = ()=>{
        handlePagincationAction(currentPagination+1);
    };

    const handleBackwardAction = ()=>{
        handlePagincationAction(currentPagination-1);
    };

    return (

        <div className={styles.pagination}>
                <div className={styles.paginationContainer}>
                    <img onClick={handleBackwardAction} className={styles.arrow} src={require("../../../../../assets/images/arrow/basicLeftArrow.svg").default}></img>
                    <div className={styles.pageNumber}>
                        {currentPagination}
                    </div>
                    <img onClick={handleForwardAction} className={styles.arrow} src={require("../../../../../assets/images/arrow/basicRightArrow.svg").default}></img>
                </div>

            </div>

    )
}

export default Pagination;
