import styles from "./patientsInvite.module.css";

const tableFields = {
    user:{
        id:1,
        label:"Patient Name",
        name:'user',
        className : styles.columnsContainer,
        typography : styles.innerClassName
    },
    phoneNumber:{
        id:2,
        label:"Phone Number",
        name:"phoneNumber",
        className : styles.columnsContainer,
        typography : styles.innerClassName
    },
    emailAddress:{
        id:3,
        label:"Email Address",
        name : "email",
        className : styles.columnsContainer,
        typography : styles.innerClassName
    },
    status:{
        id:4,
        label:"Status",
        name:"status",
        className : styles.columnsContainer,
        typography : styles.innerClassName
    },
    action:{
        id:5,
        label:"Action",
        name :"action",
        className : styles.actionField,
        typography : styles.innerClassName
    },
    viewDetails:{
        id:6,
        label: "",
        name : "viewDetails",
        className : styles.actionField,
        typography : styles.innerClassName
    }
};

const TABLE_FIELDS = {
    ACTIVE : [tableFields.user,tableFields.phoneNumber,tableFields.emailAddress, tableFields.viewDetails],
    PENDING : [tableFields.user, tableFields.phoneNumber, tableFields.emailAddress, tableFields.status, tableFields.action]
}

export {
    tableFields,
    TABLE_FIELDS
}