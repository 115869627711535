import React,{ useEffect, useState } from "react";

import Table from "./components/table/Table";
import Search from "./components/search/Search";
import { getPracticePatientDetails } from "./patients.async";

const Patients = ({setIsLoading=()=>{}, updateToast=()=>{}}) => {

    const [practicePatientList, setPracticePatientList] = useState([]);

    useEffect(()=>{
        setIsLoading(true);
        const patientList = getPracticePatientDetails() || new Promise();
        patientList.then(data=>{
            setPracticePatientList(data);
        })
        .finally(()=>{
            setIsLoading(false);
        })
    },[]);

    const handleListReFetch = ()=>{
        setIsLoading(true);
        const patientList = getPracticePatientDetails() || new Promise();
        patientList.then(data=>{
            setPracticePatientList(data);
        })
        .finally(()=>{
            setIsLoading(false);
        })
    }

    return (
        <div>
            <Search handleListReFetch={handleListReFetch} setIsLoading={setIsLoading} updateToast={updateToast}></Search>
            <Table patientList={practicePatientList}></Table>
        </div>
    )
}

export default Patients;
