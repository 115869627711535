import moment from "moment";
import { METRIC_COPY_PREFIX } from "./trendAnalysisTable.constants";

const getTrendAnalysisTableFormattedData = (data,prev=0) =>{
    let resultSet=[];

    for(let i=0;i<7;i++){
        let init={};
        init.date = moment().subtract(7-i-1+(prev*7),'d').format("ddd, MMM DD")
        init.fasting = {
            ...data?.[i]?.glucoseDetails,
            ...data?.[i]?.userMeal,
            meal: "FASTING",
            date : moment().subtract(7-i-1+(prev*7),'d').format("YYYY-MM-DD")
            },
        init.breakfast = {
            ...data?.[i+7]?.glucoseDetails,
            ...data?.[i+7]?.userMeal,
            meal:"BREAKFAST",
            date : moment().subtract(7-i-1+(prev*7),'d').format("YYYY-MM-DD")
        };
        init.lunch = {
            ...data?.[i+14]?.glucoseDetails,
            ...data?.[i+14]?.userMeal,
            meal : "LUNCH",
            date : moment().subtract(7-i-1+(prev*7),'d').format("YYYY-MM-DD")
        };
        init.dinner = {
            ...data?.[i+21]?.glucoseDetails,
            ...data?.[i+21]?.userMeal,
            meal : "DINNER",
            date : moment().subtract(7-i-1+(prev*7),'d').format("YYYY-MM-DD")
        };
        resultSet.push(init);
    }

    return resultSet;
}

const getformattedGlucoseValue = (data="")=>{
    let count = Math.round(data);
    count = count?.toString()?.length;
    if(count==0){
        return "___";
    }

    let formattedGlucoseValue="";

    for(let i=0;i<(3-count);i++){
        formattedGlucoseValue+="0";
    }

    return formattedGlucoseValue+Math.round(data);
}

const getFastingStringCopy = (data) =>{
    const { userMeal, glucoseDetails } = data || {};
    const { glucose } = glucoseDetails || {};
    const { isFastingBGBasedOnWakeUp } = userMeal || {};

    let copyString = "";

    copyString+=isFastingBGBasedOnWakeUp ? METRIC_COPY_PREFIX.USER_LOG_BASED : METRIC_COPY_PREFIX.LOGIC_BASED ;
    copyString+=" FT: "+getformattedGlucoseValue(glucose)+",";

    return copyString
}

const getOneHourPPCopyString=(data)=>{

    const { userMeal, glucoseDetails, metricRequest} = data || {};
    const { glucose } = glucoseDetails || {};
    const { isMetricsCalculatedFromMeal } = userMeal || {};
    const { mealType = "" } = metricRequest || {};

    let copyString = "";

    copyString+= isMetricsCalculatedFromMeal ? METRIC_COPY_PREFIX.USER_LOG_BASED : METRIC_COPY_PREFIX.LOGIC_BASED ;

    copyString+= " 1HR "+mealType[0]+": "+getformattedGlucoseValue(glucose)+",";

    return copyString;

}

const getMetricToCopyString = (data)=>{
    const {metricRequest} = data || {};
    const {metricType} = metricRequest || {};

    let copiedString = "";

    switch(metricType){
        case "FASTING_GLUCOSE" :
            copiedString = getFastingStringCopy(data)
            break;
        default:
            copiedString = getOneHourPPCopyString(data);
            break;
    }

    return copiedString;
}

const getCopyFormattedData = (data=[])=>{
    let copyData="==========================================================";
    copyData+="\n\n";

    for(let i=0;i<data.length/4;i++){
        const { metricRequest } = data[i] || {};
        const { date } = metricRequest || {};
        copyData+= moment(date).format("MM/DD/YYYY")+"          ";
        copyData+=getMetricToCopyString(data[i])+"          ";
        copyData+=getMetricToCopyString(data[i+7])+"          ";
        copyData+=getMetricToCopyString(data[i+14])+"          ";
        copyData+=getMetricToCopyString(data[i+21])+"\n";
    }
    copyData+="\n";
    copyData+="•: Based on Estimation         *: Based on User Logging          ___:No Data"
    copyData+="\n\n";
    copyData+="==========================================================";

    return copyData;
}

export {
    getTrendAnalysisTableFormattedData,
    getCopyFormattedData
}