import { map, forEach, filter, keys } from "lodash";
import moment from "moment";
import { getWeeklyFormattedWeeklyDates } from "../../../../helpers/dateFormatter";
import { COMPARISON_ANALYSIS_MAPPING, PROGRESS_MONITORING_CARDS_TYPES, PROGRESS_MONITORING_PRIMARY_CARD_BASIC_METRICS, TREND_ANALYSIS_TIME_DIFFERENCE_IN_MILLISECONDS } from "./progressMonitoring.constants";
import { BASIC_PRECISION_MAPPINGS } from "./progressMonitoring.mapping";

const getRequestBody = ({type,numberOfDays,mealType, prev=0})=>{
    let metrics =[]
    for(let x =2 ; x<=(numberOfDays+1);x++)
    {
        metrics.push({
            "metricType":type,
            "date": moment().subtract(8-(x%9)+(7*prev),'d').format("YYYY-MM-DD"),
            "mealType": mealType
        })
    }
    return metrics;
};

const getTrendAnalysisFilteredData = (data) =>{
    let newObj={};
    Object.keys(data).forEach((key)=>{
        let lastTimeStamp=0;
        let newArr = filter(data[key],({localTime, timestamp })=>{
            if(((new Date(timestamp).getTime())-(new Date(lastTimeStamp).getTime()))>=7200000)
            {
                lastTimeStamp=timestamp;
                return true;
            }
            return false;
        });
        newObj[key]=newArr;
    });
    return newObj;
}

const getStartDateFormattedRequestBody = ({type,numberOfDays,mealType, prev=0}) =>{
    let metrics =[]
    for(let x =2 ; x<=(numberOfDays+1);x++)
    {
        metrics.push({
            "metricType":type,
            "end_date" : moment().subtract(8-(x%9)+(7*prev),'d').format("YYYY-MM-DD"),
            "start_date" : moment().subtract(8-(x%9)+(7*prev),'d').format("YYYY-MM-DD"),
            "mealType": mealType
        })
    }
    return metrics;
}

const getDataInOrderedMealFormat=(data)=>{
    let newMealData = [];
    for(let x=0;x<7;x++)
    {
        newMealData.push(data[x]);
        newMealData.push(data[7+x]);
        newMealData.push(data[14+x]);
    }
    return newMealData;
}

const getGlucoseDataPoints = (data)=>{
    let mealData = [];
    for(let x in data)
    {
        if(data[x]?.glucoseDetails?.glucose && data[x]?.glucoseDetails?.glucose!=0 )
            mealData.push(data[x]?.glucoseDetails?.glucose);
        else
            mealData.push(null);
    }
    return mealData;
}

const getDateInOrder = (numberOfDays,spacing,prev)=>{
    let dateFormat = [];
    for(let x =2 ; x<=(numberOfDays+1);x++)
    {
        for(let y=0;y<spacing;y++)
        {
            dateFormat.push("");
        }
        dateFormat.push( moment().subtract(8-(x%9)+(7*prev),'d').format("DD-MMM"));
        for(let y=0;y<spacing;y++)
        {
            dateFormat.push("");
        }        
    }
    return dateFormat;
}


const getDataInWeeklyFormat = ({metricsType, prev=0})=>{
    return map(metricsType,({metricType, mealType})=>{
        const {startDate , endDate } = getWeeklyFormattedWeeklyDates({prev, format:"YYYY-MM-DD"})
        return {
            metricType,
            mealType,
            start_date : startDate,
            end_date : endDate,
        }
    })
}

const getRespectivePrecisionValue = (data)=>{
    let val={};
    for(let key in data){
        val[key]= BASIC_PRECISION_MAPPINGS[key](data[key]) 
    }
    return val;
}

const getConcretGlucoseValues = (data)=>{
    let glucoseMetrics={};
    const details = forEach(data,(option)=>{
            const {metricRequest={}, glucoseDetails = {}} = option;
            const {glucose = ""} = glucoseDetails || {};
            const { metricType = "" } = metricRequest;
            const metrics = PROGRESS_MONITORING_PRIMARY_CARD_BASIC_METRICS[metricType];
            glucoseMetrics={...glucoseMetrics, [metrics]:glucose}
        })
    return getRespectivePrecisionValue(glucoseMetrics);
}

const getMappedRequestBody = ({type, prev, userId}) =>{
    switch(type){
        case PROGRESS_MONITORING_CARDS_TYPES.ACTIVITY_ANALYSIS:
            const {startDate, endDate} = getWeeklyFormattedWeeklyDates({prev,format:"YYYY-MM-DD"});
            return {
                url : "dashboard/glucose/metrics/range",
                requestBody:{
                    userId,
                    start_date : startDate,
                    end_date: endDate,
                }
            };
            break;
        case PROGRESS_MONITORING_CARDS_TYPES.TREND_ANALYSIS:
            const {startDate : start, endDate: end} = getWeeklyFormattedWeeklyDates({prev,format:"YYYY-MM-DD"});
            return {
                url : "dashboard/glucose/logs/range/intervals",
                requestBody:{
                    userId,
                    start_date : start,
                    end_date: end,
                }};
                break;
        case PROGRESS_MONITORING_CARDS_TYPES.DAY_BY_DAY_TREND_ANALYSIS :
                const fastingGlucoseBody = getRequestBody({type:"FASTING_GLUCOSE",numberOfDays: 7,mealType:"BREAKFAST",prev});
                const breakfastFastingBody = getRequestBody({type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"BREAKFAST",prev});
                const lunchFastingBody = getRequestBody({type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"LUNCH",prev});
                const dinnerFastingBody = getRequestBody({type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"DINNER",prev});
                return {
                    requestBody:{
                        userId,
                        metricRequests : [
                            ...fastingGlucoseBody,
                            ...breakfastFastingBody,
                            ...lunchFastingBody,
                            ...dinnerFastingBody
                        ]
                    }
                }
                break;
        case PROGRESS_MONITORING_CARDS_TYPES.COMPARISON_ANALYSIS:
            return {requestBody:{
                userId,
                metricRequests:[
                    ...getDataInWeeklyFormat({prev:1,metricsType:[{
                        metricType:"FASTING_TIME_IN_RANGE",
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: COMPARISON_ANALYSIS_MAPPING[prev].PRIMARY,
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: COMPARISON_ANALYSIS_MAPPING[prev].PRIMARY,
                        mealType: "LUNCH"
                    },
                    {
                        metricType: COMPARISON_ANALYSIS_MAPPING[prev].PRIMARY,
                        mealType: "DINNER"
                    }]}),
                    ...getDataInWeeklyFormat({metricsType:[{
                        metricType:"FASTING_TIME_IN_RANGE",
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: COMPARISON_ANALYSIS_MAPPING[prev].PRIMARY,
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: COMPARISON_ANALYSIS_MAPPING[prev].PRIMARY,
                        mealType: "LUNCH"
                    },
                    {
                        metricType: COMPARISON_ANALYSIS_MAPPING[prev].PRIMARY,
                        mealType: "DINNER"
                    }]}),
                    ...getDataInWeeklyFormat({prev:1,metricsType:[{
                        metricType:"AVG_FASTING_GLUCOSE",
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: COMPARISON_ANALYSIS_MAPPING[prev].SECONDARY,
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: COMPARISON_ANALYSIS_MAPPING[prev].SECONDARY,
                        mealType: "LUNCH"
                    },
                    {
                        metricType: COMPARISON_ANALYSIS_MAPPING[prev].SECONDARY,
                        mealType: "DINNER"
                    }]}),
                    ...getDataInWeeklyFormat({metricsType:[{
                        metricType:"AVG_FASTING_GLUCOSE",
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: COMPARISON_ANALYSIS_MAPPING[prev].SECONDARY,
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: COMPARISON_ANALYSIS_MAPPING[prev].SECONDARY,
                        mealType: "LUNCH"
                    },
                    {
                        metricType: COMPARISON_ANALYSIS_MAPPING[prev].SECONDARY,
                        mealType: "DINNER"
                    }]})
            ]}}
            break;
        case PROGRESS_MONITORING_CARDS_TYPES.PRIMARY_CARD:
            return {requestBody : {
                userId,
                metricRequests:[
                    ...getDataInWeeklyFormat({metricsType:[{
                        metricType: "OVERALL_TIME_IN_RANGE",
                        mealType: "DINNER"
                    },
                    {
                        metricType: "AVERAGE_GLUCOSE",
                        numberOfDays: 7,
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: "GMI",
                        numberOfDays: 7,
                        mealType: "DINNER"
                    },
                    {
                        metricType: "STANDARD_DEVIATION",
                        numberOfDays: 7,
                        mealType: "DINNER"
                    },
                    {
                        metricType: "GLUCOSE_VERIABILITY",
                        numberOfDays: 7,
                        mealType: "DINNER"
                    }], prev})
                ]}}
            break;
    }
}

const getMappedResponseBody = ({type, response})=>{
    switch(type){
        case PROGRESS_MONITORING_CARDS_TYPES.ACTIVITY_ANALYSIS:
            return {
            activityAnalysisData: response?.["blood-glucose-metrics"],
            };
            break;
        case PROGRESS_MONITORING_CARDS_TYPES.COMPARISON_ANALYSIS:
            return {
                tableChartData:[response?.metricsResponses?.slice(0,4),response?.metricsResponses?.slice(4,8),response?.metricsResponses?.slice(8,12),response?.metricsResponses?.slice(12,16)],
            };
            break;
        case PROGRESS_MONITORING_CARDS_TYPES.PRIMARY_CARD:
            return {
                summary:{
                    overallTimeInRange : response?.metricsResponses?.[0],
                    primaryCard : getConcretGlucoseValues(response?.metricsResponses?.slice(1,response?.metricsResponses?.length))
                },    
            };
            break;
        case PROGRESS_MONITORING_CARDS_TYPES.DAY_BY_DAY_TREND_ANALYSIS:{
            return {
                trendAnalysisTableData : response?.metricsResponses
            }
        }
        case PROGRESS_MONITORING_CARDS_TYPES.TREND_ANALYSIS:
            return {
                trendAnalysisMainData : response?.["glucose_logs"]
            }
            break;
    }
}

const getComparisonAnalysisColumns = ()=>{
    return {
        gant1:{
            ...getWeeklyFormattedWeeklyDates({prev:0,format:"MMM DD"})
        },
        gant2:{
            ...getWeeklyFormattedWeeklyDates({prev:1,format:"MMM DD"})
        }
    }
}

const getActivityAnalysisTimings = ({time,difference}) =>{
    return time >= 12 ? (time%difference == 0 ? `${(time%12 ==0 ? time :time-12)} pm` : "") : (time%difference == 0 ? (time/12==0 ? `12 am` : `${(time)} am`) : "");
}

const getFormattedTimings = (data)=>{
    let time = data.split(".");
    if(time!=null && time[0]?.length==1)
    {
        time[0]='0'+time[0];
    }
    if(time!=null && time[1]?.length<=4)
    {
        let minute = time[1].split(" ");
        minute[0]=minute[0]*6;
        time[1]=minute.join(" ");
    }

    return time.join(":");
}

const getActivityAnalysisProps = (actvityAnalysisData)=>{
    let columns = [];
    let minAndMaxRange= [];
    let activityData = [];
    let timeData = [];
    forEach(actvityAnalysisData,(analysisData)=>{
        columns.push(getActivityAnalysisTimings({time :analysisData?.["interval_start"]/2,difference:4}));
        if(analysisData?.["max-glucose-level"] != 0 && analysisData?.["min-glucose-level"]!=0)
            minAndMaxRange.push([analysisData?.["max-glucose-level"],analysisData?.["min-glucose-level"]]);
        else
           minAndMaxRange.push([null,null])
        if(analysisData?.["avg-glucose-level"] != 0)
            activityData.push([analysisData?.["avg-glucose-level"],analysisData?.["avg-glucose-level"]]);
        else
            activityData.push(null);

        timeData.push(getFormattedTimings(getActivityAnalysisTimings({time :analysisData?.["interval_start"]/2,difference:0.5})));
    })
    return {
        columns,
        minAndMaxRange,
        activityData,
        timeData
    }
}

const getTrendAnalysisMainData = (data={},activeDurations) =>{

    let arr = [];

    let columns = [];

    Object.keys(data).sort().forEach((key,index)=>{

        let dateFormat = [];

        arr.push(...data?.[key]);

        for(let y=0;y<=(data?.[key]?.length/2-1);y++)
        {
            dateFormat.push("");
        }
        dateFormat.push( moment().subtract(6-(Object.keys(data).length-index-1)+(7*(activeDurations?.["current"] || 0)),'d').format("DD-MMM"));
        for(let y=0;y<=data?.[key]?.length/2;y++)
        {
            dateFormat.push("");
        }        
        columns=[...dateFormat,...columns]

    })

    return {
        trendAnalysisData : arr,
        trendAnalysisColumns : columns
    };
}

export {
    getRequestBody,
    getDataInOrderedMealFormat,
    getGlucoseDataPoints,
    getDateInOrder,
    getDataInWeeklyFormat,
    getMappedRequestBody,
    getMappedResponseBody,
    getComparisonAnalysisColumns,
    getActivityAnalysisProps,
    getConcretGlucoseValues,
    getStartDateFormattedRequestBody,
    getTrendAnalysisFilteredData,
    getTrendAnalysisMainData
}