import { useState } from "react";

import Heading from "./components/heading/Heading";
import PageHeaderContext from "./components/pageHeaderContext/PageHeaderContext";
import Tabs from "./components/tabs/Tabs";
import Search from "./components/search/Search";
import EmptyBag from "./components/emptyBag/EmptyBag";
import Pagination from "./components/pagination/Pagination"
import AddNewMemberForm from "./components/form/AddNewMemberForm";
import useCoachManagement from "./hooks/useCoachManagement";

import Table from "./components/table/Table";
import useCoachForm from "./hooks/useCoachForm";

import useToast from "./hooks/useToast";
import BasicToast from "../../molecules/baseComponents/toasts/BasicToast";
import DefaultSpinner from "../../atoms/spinner/Spinner";

import styles from "./rrxCoach.module.css";

const RrxCoach = () => {

    const [isLoading , setIsLoading] = useState(false);
    const [ currentPagination, setCurrentPagination] = useState(1);

    const {activeTab,coachList,updateActiveTab, resendInviteAction, updateCoachList} = useCoachManagement({tab : "ACTIVE", setIsLoading});

    const { formValues, submitFormValues, updateFormValues} = useCoachForm(updateActiveTab,updateCoachList);

    const [handleCloseToastDisplay=()=>{},handleOpenToastDisplay=()=>{},toastType,displayToast,message ] = useToast();

    const paginatedList = coachList?.slice(10*(currentPagination-1),10*(currentPagination))

    const handlePagincationAction = (updatedPagination)=>{
        if(updatedPagination<=0 || updatedPagination>Math.ceil(coachList.length/10)){
            return;
        }

        setCurrentPagination(updatedPagination);
    }

    return (
        <div className={styles.mainContainer}>
            {displayToast && 
            <BasicToast 
                type={toastType} 
                display={displayToast} 
                handleToastToggle={handleCloseToastDisplay}
                message={message}
            />}
            {isLoading && <DefaultSpinner className={styles.loader} />}
            <PageHeaderContext />
            <Heading setIsLoading={setIsLoading} handleOpenToastDisplay={handleOpenToastDisplay} submitFormValues={submitFormValues} updateFormValues={updateFormValues} formValues={formValues}/>
            <Tabs  activeTab={activeTab} updateActiveTab={updateActiveTab} />
            <Search />
            {coachList?.length>0 && <Table resentInviteAction={resendInviteAction}  setIsLoading={setIsLoading} tab={activeTab} listItems={paginatedList}/>}
            {coachList?.length>0 && <Pagination currentPagination={currentPagination} handlePagincationAction={handlePagincationAction}/>}
            {coachList?.length==0 &&  <EmptyBag updateFormValues={updateFormValues} formValues={formValues}/>}
        </div>
    )
}

export default RrxCoach;