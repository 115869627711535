import React from "react";

import leftArrow from "../../../../../../../assets/images/arrow/active/basicLeftArrow.svg";
import rightArrow from "../../../../../../../assets/images/arrow/active/basicRightArrow.svg";

import styles from "./pagination.module.css";

const Pagination = ({currentPage,handleShowNextListItems, handleShowPreviousListItems})=>{
    return (
        <>
        <img src={leftArrow} className={styles.arrow} onClick={handleShowPreviousListItems}/>
        <div className={styles.activeMainContainer}>
            {currentPage}
        </div>
        <img src={rightArrow} onClick={handleShowNextListItems}/>
        </>
    )
}

export default Pagination;