
const GLUCOSE_METRICS_TYPES = {
    AVG_FASTING_GLUCOSE :'AVG_FASTING_GLUCOSE',
    FASTING_TIME_IN_RANGE:'FASTING_TIME_IN_RANGE',
    POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE:"POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
    POST_PRANDIAL_TWO_HOUR_TIME_IN_RANGE:"POST_PRANDIAL_TWO_HOUR_TIME_IN_RANGE",
}

const MEDICATION_TRACER_FORMAT ={
    OVERALL : "Overall",
    WEEKLY : "Weekly",
    DAILY : "Daily",
    MONTHLY : "Monthly"
}

export {
    GLUCOSE_METRICS_TYPES,
    MEDICATION_TRACER_FORMAT
}