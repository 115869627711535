import mealIcon from "./../../../../../../../../../assets/images/meals/basicFoodLog.svg";
import wakeUpIcon from "./../../../../../../../../../assets/images/duration/wakeUp.svg"
import highIcon from "./../../../../../../../../../assets/images/exclamation/yellowFilledExclamation.svg";
import lowIcon from "./../../../../../../../../../assets/images/criticleSquare.svg"

const ICON_MARKERS = [{
       icon: mealIcon,
       label : "Meal Logged"
    },
    {
        icon : wakeUpIcon,
        label : "Wakeup Logged"
    }
];

const ALERT_ICONS = [{
    icon: lowIcon,
    label : "Low"
 },
 {
     icon : highIcon,
     label : "High"
 }];

export {
    ALERT_ICONS
}

export default ICON_MARKERS;