import React from "react";
import { useNavigate } from "react-router";
import {map} from "lodash";

import { getTextInitials } from "../../../../helpers/TextManipulations.helpers";
import { TABS } from "./table.constants";

import styles from "./table.module.css";

const Table = ({listItems, resentInviteAction, tab}) => {

    const navigate = useNavigate();

    const handleResendInviteAction =(id,practiceName)=>()=>{
        if(tab==TABS.ACTIVE){
            navigate("/superUser/"+id+"/"+practiceName);
            return;
        }
        resentInviteAction(id);
    }

    return (
        
            <div className={styles.practiceListContainer}>
                <div className={styles.tableHeader}>
                    <div className={styles.tableColumn}>NAME<img src={require("../../../../../assets/images/sort-icon.svg").default} className={styles.searchLogo}></img></div>
                    <div className={styles.tableColumn}>ADMIN</div>
                    <div className={styles.tableColumn}>EMAIL</div>
                    <div className={styles.tableColumnAction}>ACTION</div>
                </div>
                <div className={styles.tableColumnContainer}>
                    {
                        map(listItems,(item)=>{
                            const {id="",practiceName="", practiceAdminName="", practiceWebsite="",practiceAdminEmail="" } = item || {};
                            return (
                                <div className={styles.tableColumnData}>
                                <div className={styles.tableColumnDataContainer}>
                                    <div className={styles.avatar}>
                                        <div className={styles.avatarData}>
                                            {getTextInitials(practiceName)}
                                        </div>
                                    </div>
                                    <div className={styles.nameAndUrlContainer}>
                                        <div className={styles.practiceName}>
                                            {practiceName}
                                        </div>
                                        <div className={styles.practiceUrl}>
                                            {practiceWebsite}
                                        </div>
                                    </div>
                                </div>
        
                                <div className={styles.tableColumnDataContainer}>
                                    <div className={styles.avatar}>
                                        <div className={styles.avatarData}>
                                            {getTextInitials(practiceAdminName)}
                                        </div>
                                    </div>
                                    <div className={styles.nameAndUrlContainer}>
                                        <div className={styles.practiceName}>
                                            {practiceAdminName}
                                        </div>
                                    </div>
                                </div>
        
                                <div className={styles.tableColumnDataContainer}>
                                    <div className={styles.nameAndUrlContainer}>
                                        <div className={styles.practiceName}>
                                            {practiceAdminEmail}
                                        </div>
                                    </div>
                                </div>
        
                                <div className={styles.tableColumnDataContainer}>
        
                                    <div className={styles.resendInvite}>
                                        <div className={styles.resendInviteText} onClick={handleResendInviteAction(id,practiceName)}>
                                            {tab==TABS.ACTIVE ? "View Details": "Resend Invite"}
                                        </div>
                                    </div>
        
                                </div>
        
                                </div>        
                            )
                        })
                    }

                </div>
            </div>
            
       


    )
}

export default Table;
