import React, { useState } from "react";
import cx from "classnames";

import MultiSelect from "../../../../../../../../../../molecules/Multiselect/MultiSelect";
import DropdownOption from "./component/DropdownOption/DropdownOption";
import { BILLING_BODY_POPUP } from "../../billingPopup.content";

import SelectedItemList from "./component/SelectedItemList";

import styles from "./body.module.css";

const Body = ({handleModalToggle: handleModalSubmit}) =>{

    const [currentBillingOptions , setCurrentBillingOptions] = useState([]);
    const [selectedActiveList, setSelectedActiveList] = useState([]);


    const handleBillingUpdate = ({label})=>(value)=>{
        setCurrentBillingOptions((prev)=>{
            return [...value]
        })
    }

    const handleModalSubmitAction = ()=>{
        handleModalSubmit(currentBillingOptions);
    }

    const handleRemoveSelectedActiveList = (value)=>{
        let arr = [...selectedActiveList];
        arr= selectedActiveList?.filter((val)=> val !=value);
        setSelectedActiveList(arr);
    }

    const handleSetSelectedActiveList = (value) =>{
        let arr = [...selectedActiveList];
        arr.push(value);
        setSelectedActiveList(arr);
    }

    return (
        <div className={styles.container}>
            <div>Billing For</div>
            <MultiSelect inputContainerClass={styles.inputContainerClass} handleFieldChange={handleBillingUpdate} placeholder={"Choose what you're billing for"} options={BILLING_BODY_POPUP} CustomDropDownComponent={DropdownOption} />
            <SelectedItemList handleSetSelectedActiveList={handleSetSelectedActiveList} handleRemoveSelectedActiveList={handleRemoveSelectedActiveList} listItems={currentBillingOptions} />
            <div onClick={handleModalSubmitAction} className={`${styles.submitButton} ${(selectedActiveList?.length == currentBillingOptions?.length && currentBillingOptions?.length >0) ? styles.activeStyles : ""}`}>
                Submit
            </div>
        </div>
    )
}

export default Body;