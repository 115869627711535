import styles from "./pageHeaderContext.module.css"

const PageHeaderContext = ({coachDetails={}}) => {

    const {firstName="", lastName=""} = coachDetails;

    return (
        
            <div className={styles.pageHeaderContext}>
                <div className={styles.subHeading}><div className={styles.subHeadingPractice}> RRX Coach </div>/  {firstName} {lastName}</div>
            </div>

    )
}

export default PageHeaderContext;