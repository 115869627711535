import styles from "./pageHeaderContext.module.css"

const PageHeaderContext = ({practiceName, memberName}) => {

    return (
        
            <div className={styles.pageHeaderContext}>
                <div className={styles.subHeading}><div className={styles.subHeadingPractice}>Practice Management  /  {practiceName}  </div>/  {memberName}</div>
            </div>

    )
}

export default PageHeaderContext;