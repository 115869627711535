import { BASE_URL } from "../../../../constants/api";
import { LOCAL_STORAGE_KEYS } from "../../../../constants/keyConstants";
import { getDataInOrderedMealFormat, getDataInWeeklyFormat, getMappedRequestBody, getRequestBody, getMappedResponseBody, getConcretGlucoseValues, getTrendAnalysisFilteredData } from "./progressMonitoring.helper";
import { GLUCOSE_METRICS_TYPES } from "../../../../constants/metricTypes";
import { getWeeklyFormattedWeeklyDates } from "../../../../helpers/dateFormatter";

const getInitAction = async({state,setState}) =>{
    const { handleToggleLoaderAction = ()=>{}} = state;
    try{
        handleToggleLoaderAction({isLoading : true});
        setState((prev)=>{
            return {
                ...prev,
                activeDurations:{
                    "ComparisonAnalysis":GLUCOSE_METRICS_TYPES.POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE,
                },
                filterDuration: {
                    current : 0,
                    comparisonAnalysis : 1
                },
            }
        })

        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");
        
        const fastingGlucoseBody = getRequestBody({type:"FASTING_GLUCOSE",numberOfDays: 7,mealType:"BREAKFAST"});
        const breakfastFastingBody = getRequestBody({type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"BREAKFAST"});
        const lunchFastingBody = getRequestBody({type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"LUNCH"});
        const dinnerFastingBody = getRequestBody({type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"DINNER"});
        
        const response = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
            method:"POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body : JSON.stringify(({
                userId: pathname?.[2],
                metricRequests: [
                        ...getDataInWeeklyFormat({prev:1,metricsType:[{
                            metricType:"FASTING_TIME_IN_RANGE",
                            mealType :"BREAKFAST"
                        },
                        {
                            metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                            mealType: "BREAKFAST"
                        },
                        {
                            metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                            mealType: "LUNCH"
                        },
                        {
                            metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                            mealType: "DINNER"
                        }]}),
                        ...getDataInWeeklyFormat({metricsType:[{
                            metricType:"FASTING_TIME_IN_RANGE",
                            mealType :"BREAKFAST"
                        },
                        {
                            metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                            mealType: "BREAKFAST"
                        },
                        {
                            metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                            mealType: "LUNCH"
                        },
                        {
                            metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                            mealType: "DINNER"
                        }]}),
                        ...getDataInWeeklyFormat({prev:1,metricsType:[{
                            metricType:"AVG_FASTING_GLUCOSE",
                            mealType :"BREAKFAST"
                        },
                        {
                            metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                            mealType: "BREAKFAST"
                        },
                        {
                            metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                            mealType: "LUNCH"
                        },
                        {
                            metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                            mealType: "DINNER"
                        }]}),
                        ...getDataInWeeklyFormat({metricsType:[{
                            metricType:"AVG_FASTING_GLUCOSE",
                            mealType :"BREAKFAST"
                        },
                        {
                            metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                            mealType: "BREAKFAST"
                        },
                        {
                            metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                            mealType: "LUNCH"
                        },
                        {
                            metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                            mealType: "DINNER"
                        }]}),
                        {
                            metricType: "OVERALL_TIME_IN_RANGE",
                            numberOfDays: 7,
                            mealType: "DINNER"
                        },
                        ...getDataInWeeklyFormat({metricsType:[{
                            metricType: "AVERAGE_GLUCOSE",
                            mealType :"BREAKFAST"
                        }]}),
                        {
                            metricType: "GMI",
                            numberOfDays: 7,
                            mealType: "DINNER"
                        },
                        {
                            metricType: "STANDARD_DEVIATION",
                            numberOfDays: 7,
                            mealType: "DINNER"
                        },
                        {
                            metricType: "GLUCOSE_VERIABILITY",
                            numberOfDays: 7,
                            mealType: "DINNER"
                        },
                        ...fastingGlucoseBody,
                        ...breakfastFastingBody,
                        ...lunchFastingBody,
                        ...dinnerFastingBody
                    ]
            }))
        });

        const data = await response.json();

        const {startDate, endDate} = getWeeklyFormattedWeeklyDates({format:"YYYY-MM-DD"})

        const responseData = await fetch(`${BASE_URL}/dashboard/glucose/metrics/range`,{
            method:"POST",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                end_date : endDate,
                start_date: startDate
            })
        });


        const responseTrendData = await fetch(`${BASE_URL}/dashboard/glucose/logs/range/intervals`,{
            method:"POST",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                end_date : endDate,
                start_date: startDate
            })
        });

        const activityAnalysisData = await responseData.json();

        const trendAnalysisData = await responseTrendData.json();

        // const fastingGlucoseInMealFormat = getDataInOrderedMealFormat(data?.metricsResponses?.slice(28,data?.metricsResponses?.length));

        setState((prev)=>({
            ...prev,
            tableChartData:[data?.metricsResponses?.slice(0,4),data?.metricsResponses?.slice(4,8),data?.metricsResponses?.slice(8,12),data?.metricsResponses?.slice(12,16)],
            // comparisonAnalysisData : fastingGlucoseInMealFormat,
            summary:{
                overallTimeInRange : data?.metricsResponses[16],
                primaryCard: getConcretGlucoseValues(data?.metricsResponses?.slice(17,21)),
                primaryCardFullData : data?.metricsResponses?.slice(17,21)
            },
            trendAnalysisTableData : data?.metricsResponses?.slice(21,data?.metricsResponses?.length),
            trendAnalysisMainData: trendAnalysisData?.["glucose_logs"],
            activityAnalysisData: activityAnalysisData?.["blood-glucose-metrics"],
            activeDurations:{
                "ComparisonAnalysis":'ONE_HOUR',
            },
            filterDuration: {
                current : 0,
                comparisonAnalysis : 1
            },
        }));
        handleToggleLoaderAction({isLoading : false});
    }
    catch(e)
    {
        setState((prev)=>({
            ...prev,
            error:e
        }));
        handleToggleLoaderAction({isLoading : false});
    }
}

const getCurrentPageFilterUpdate = async({state, setState, payload})=>{
    try{
        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const {previous = 0} = payload || {}

        const fastingGlucoseBody = getRequestBody({prev: previous, type:"FASTING_GLUCOSE",numberOfDays: 7,mealType:"BREAKFAST"});
        const breakfastFastingBody = getRequestBody({prev: previous, type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"BREAKFAST"});
        const lunchFastingBody = getRequestBody({prev: previous, type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"LUNCH"});
        const dinnerFastingBody = getRequestBody({prev: previous, type:"ONE_HOUR_POST_PRANDIAL",numberOfDays: 7,mealType:"DINNER"});

        const response = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
            method:"POST",
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body : JSON.stringify(({
                userId: pathname?.[2],
                metricRequests: [
                    ...getDataInWeeklyFormat({metricsType:[{
                            metricType: "OVERALL_TIME_IN_RANGE",
                            mealType: "DINNER"
                        },
                        {
                            metricType: "AVERAGE_GLUCOSE",
                            numberOfDays: 7,
                            mealType: "BREAKFAST"
                        },
                        {
                            metricType: "GMI",
                            numberOfDays: 7,
                            mealType: "DINNER"
                        },
                        {
                            metricType: "STANDARD_DEVIATION",
                            numberOfDays: 7,
                            mealType: "DINNER"
                        },
                        {
                            metricType: "GLUCOSE_VERIABILITY",
                            numberOfDays: 7,
                            mealType: "DINNER"
                        }], prev : previous}),

                        ...fastingGlucoseBody,
                        ...breakfastFastingBody,
                        ...lunchFastingBody,
                        ...dinnerFastingBody
                    ]
            }))
        });

        const {startDate, endDate} = getWeeklyFormattedWeeklyDates({format:"YYYY-MM-DD", prev : previous})

        const responseData = await fetch(`${BASE_URL}/dashboard/glucose/metrics/range`,{
            method:"POST",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                end_date : endDate,
                start_date: startDate
            })
        });


        const responseTrendData = await fetch(`${BASE_URL}/dashboard/glucose/logs/range/intervals`,{
            method:"POST",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body: JSON.stringify({
                userId: pathname?.[2],
                end_date : endDate,
                start_date: startDate
            })
        });

        const activityAnalysisData = await responseData.json();

        const trendAnalysisData = await responseTrendData.json();

        const data = await response.json();

        setState((prev)=>({
            ...prev,
            summary:{
                overallTimeInRange : data?.metricsResponses[0],
                primaryCard: getConcretGlucoseValues(data?.metricsResponses?.slice(1,5)),
                primaryCardFullData : data?.metricsResponses?.slice(1,5)
            },
            trendAnalysisTableData : data?.metricsResponses?.slice(5,data?.metricsResponses?.length),
            trendAnalysisMainData: trendAnalysisData?.["glucose_logs"],
            activityAnalysisData: activityAnalysisData?.["blood-glucose-metrics"],
            filterDuration: {
                ...prev?.filterDuration,
                current : previous,
            },
        }));
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error : e
            }
        })
    }
};

const getComparisonAnalysisFilterUpdate = async({setState, payload})=>{
    try{

        const {prev: previous=0} = payload || {};

        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");


        const responseData = await fetch(`${BASE_URL}/dashboard/glucoseMetrics`,{
            method : "POST",
            headers : {
                'content-type' : "application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body : JSON.stringify({
                userId : pathname?.[2],
                metricRequests: [
                    ...getDataInWeeklyFormat({prev:previous,metricsType:[{
                        metricType:"FASTING_TIME_IN_RANGE",
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "LUNCH"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "DINNER"
                    }]}),
                    ...getDataInWeeklyFormat({ metricsType:[{
                        metricType:"FASTING_TIME_IN_RANGE",
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "LUNCH"
                    },
                    {
                        metricType: "POST_PRANDIAL_ONE_HOUR_TIME_IN_RANGE",
                        mealType: "DINNER"
                    }]}),
                    ...getDataInWeeklyFormat({prev: previous,metricsType:[{
                        metricType:"AVG_FASTING_GLUCOSE",
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        mealType: "LUNCH"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        mealType: "DINNER"
                    }]}),
                    ...getDataInWeeklyFormat({ metricsType:[{
                        metricType:"AVG_FASTING_GLUCOSE",
                        mealType :"BREAKFAST"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        mealType: "BREAKFAST"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        mealType: "LUNCH"
                    },
                    {
                        metricType: "AVERAGE_ONE_HOUR_POST_PRANDIAL",
                        mealType: "DINNER"
                    }]}),
                ]
            })
        });

        const comparisonData = await responseData.json();

        setState((prev)=>{
            return {
                ...prev,
                filterDuration: {
                    ...prev?.filterDuration,
                    comparisonAnalysis : previous,
                },
                tableChartData:[comparisonData?.metricsResponses?.slice(0,4),comparisonData?.metricsResponses?.slice(4,8),comparisonData?.metricsResponses?.slice(8,12),comparisonData?.metricsResponses?.slice(12,16)],

            }
        });
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error : e
            }
        })
    }
}

const getProgressMonitoringDurationUpdate = async({state, setState, payload}) =>{
    const { handleToggleLoaderAction = ()=>{}} = state;
    try{
        handleToggleLoaderAction({isLoading : true});
        switch(payload?.name){
            case "comparisonAnalysis":
                getComparisonAnalysisFilterUpdate({setState, state, payload})
                .then(()=>{
                    handleToggleLoaderAction({isLoading : false});
                })
                break;
            default:
                getCurrentPageFilterUpdate({state, setState, payload})
                .then(()=>{
                    handleToggleLoaderAction({isLoading : false});
                })
                break;
        }
    }
    catch(e)
    {
        setState((prev)=>({
            ...prev,
            error :e,
        }));
        handleToggleLoaderAction({isLoading : false});
    }
}

const getInternalTabUpdateAction = ({setState,payload,state}) =>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                currentInternalTabState : payload
            }
        })
    }
    catch(e){
        setState((prev)=>({
            ...prev,
            error :e,
        }));
    }
}

export default {
    INIT_ACTION : getInitAction,
    UPDATE_PROGRESS_MONITORING_DURATION : getProgressMonitoringDurationUpdate,
    UPDATE_INTERNAL_TAB : getInternalTabUpdateAction,
    UPDATE_COMPARISON_ANALYSIS_TYPE : ()=>{}
}