
import ManageRrxCoach from "../../../assets/iconComponents/ManageRrxCoach";
import PatientIcon from "../../../assets/iconComponents/PatientIcon";
import SettingsIcon from "../../../assets/iconComponents/SettingsIcon";

const getCommonSidebarTabs = () =>{
    return [
    {
        label:"Manage Practice",
        name:"managePractice",
        ImageIcon: PatientIcon,
        link:"/superUser"
    },
    {
        label:"Manage RRX Coach",
        name:"manageRrxCoach",
        ImageIcon: ManageRrxCoach,
        link:"/rrxCoach"
    },
    {
        label:"Settings",
        name:"settings",
        ImageIcon: SettingsIcon,
        link:"/superUser"
    }
]
} 

export {
    getCommonSidebarTabs,
}