import React from "react";
import {map} from "lodash";

import exclamationIcon from "./../../../../../../../../../assets/images/exclamation/greyExclamation.svg";

import styles from "./trendAnalysisTable.module.css"
import BaseTable from "../../../../../../../tables/BaseTable";
import { getTrendAnalysisTableFields, getTrendAnalysisTableWrapperFields } from "./trendAnalysisTable.fields";
import { getCopyFormattedData, getTrendAnalysisTableFormattedData } from "./trendAnalysisTable.helpers";

import ICON_MARKERS, { ALERT_ICONS } from "./trendAnalysisTable.mapping.js";

const TrendAnalysisTable = ({trendAnalysisTableData=[], previous, handleToastUpdateAction}) =>{

    const tableData = getTrendAnalysisTableFormattedData(trendAnalysisTableData, previous);

    const handleTableDataCopyAction = async() =>{
        try{
            let copyData = getCopyFormattedData(trendAnalysisTableData);
            navigator.clipboard.writeText(copyData)
            .then(()=>{handleToastUpdateAction({
                enableToast: true,
                toastProps :{
                    message : "Glucose Log Sheet successfully copied to Clipboard",
                    type : "success"
                }
            })})
        }
        catch(e){
            console.log('Copy Failed');
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.topContainer}>
                <div className={styles.topLeftContainer}>
                    <div className={styles.primaryText}>
                            <span>Glucose Log Sheet</span>
                            <img src={exclamationIcon} className={styles.exclamationIcon}/>
                        <div className={styles.betaIconContainer}>
                            Beta
                        </div>
                    </div>
                    <div className={styles.secondaryText}>
                        Patient provided (or auto detected) glucose value based on time of date
                    </div>
                </div>
                <div className={styles.rightContainer}>
                    <div className={styles.copyDataOuterContainer} onClick={handleTableDataCopyAction}>
                        <div className={styles.copyDataInnerContainer}>Copy Data</div>
                    </div>
                </div>
            </div>
            <BaseTable columnProps={{ className : styles.columnRow}} wrapperFields={getTrendAnalysisTableWrapperFields()} columnsData={getTrendAnalysisTableFields()} tableData={tableData} tableDataProps={{className : styles.tableRow }}/>
            <div className={styles.footerContainer}>
                <div className={styles.footerTagContainer}>
                    {map(ICON_MARKERS,(content)=>{
                        const {icon, label} = content;
                        return (
                            <div className={styles.tags}>
                                <img src={icon} className={styles.tagIcon}/>
                                <p className={styles.tagLabel}>{label}</p>
                            </div>
                        )
                    })}
                </div>
                {/* <div className={styles.footerTagContainer}>
                    {map(ALERT_ICONS,(content)=>{
                            const {icon, label} = content;
                            return (
                                <div className={styles.tags}>
                                    <img src={icon} className={styles.tagIcon}/>
                                    <p className={styles.tagLabel}>{label}</p>
                                </div>
                            )
                        })}
                </div> */}
            </div>
        </div>
    )
}

export default TrendAnalysisTable;