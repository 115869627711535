import moment from "moment";
import { BASE_URL } from "../../../../../../constants/api";
import { LOCAL_STORAGE_KEYS } from "../../../../../../constants/keyConstants";

const handleAddBillingOption = async(items)=>{
    try{

        const url = new URL(window.location.href);
        const pathname = url.pathname.split("/");

        const request = await fetch(`${BASE_URL}/api/dashboard/users/addNewBill`,{
            method:"POST",
            headers:{
                'content-type':"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
            body : JSON.stringify({
                billingDate : moment().format("YYYY-MM-DD"),
                careProUserId : window?.localStorage?.getItem("id"),
                userId : pathname?.[2],
                type : items?.[0].split(" ").join("_").toUpperCase(),
            })
        });

        return request.status;
    }
    catch(e){
        console.log(e);
    }
}

export {
    handleAddBillingOption
}