import moment from "moment"
import ModalBody from "../../../BalancedPlate/components/TableContent/components/ModalBody/ModalBody"
import ModalHeader from "../../../BalancedPlate/components/TableContent/components/ModalHeader/ModalHeader"


import wakeUpIcon from "./../../../../../../../../../../../assets/images/duration/wakeUp.svg";
import mealIcon from "./../../../../../../../../../../../assets/images/meals/basicFoodLog.svg";

const getRespectiveColorRanges = (data)=>{
    return data<=63 ? 'RED' : data>=140 ? "YELLOW" : 'GREEN'
}

const getRespectiveModelContent = ({loggedTime}) =>{
    return {
        HeaderComponent : ModalHeader,
        BodyComponent : ModalBody,
        headerProps :{
            date : moment(loggedTime).format("MMM DD,YYYY - ddd "),
            time : moment(loggedTime).format("h:mm A"),
            label : "Day-by-Day Glucose Trend Analysis"
        }
    }
}

const getRespectiveIcon = (data)=>{
    switch(data?.meal){
        case "FASTING" : 
            return data?.isFastingBGBasedOnWakeUp ? wakeUpIcon : null;
            break;
        default:
            return data?.isMetricsCalculatedFromMeal ? mealIcon : null;
            break;
    }
}


export {
    getRespectiveColorRanges,
    getRespectiveModelContent,
    getRespectiveIcon
}