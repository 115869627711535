import { API_METHOD_TYPES, BASE_URL } from "../../../../constants/api";
import { LOCAL_STORAGE_KEYS } from "../../../../constants/keyConstants";

const getPracticePatientDetails = async() =>{
    try{
        const url = new URL(window.location.href)
        const pathname = url.pathname.split("/");

        const practiceId = pathname?.[3] || "";

        const request = await fetch(`${BASE_URL}/dashboard/admin/api/rrxsuperboss/users/patientsByStatus/${practiceId}?status=ACTIVE`,{
            method: API_METHOD_TYPES.GET,
            headers:{
                "content-type":"application/json",
                'authorization' : 'Bearer '+ window?.localStorage?.getItem(LOCAL_STORAGE_KEYS.AUTHORIZATION),
            },
        });

        const practicePatientList = await request.json();

        console.log('PRACTICE IS : ', practicePatientList);

        return practicePatientList?.practicePatientList;

    }
    catch(e){
        console.log('FAILED TO FETCH PATIENTS : ',e);
    }
}

export {
    getPracticePatientDetails
}