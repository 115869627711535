import { BASE_URL } from "../../constants/api"
import { INVITE_PATIENT_STATUS } from "../../fieldMapping/invitePatients";

const getToastStatusCloseUpdate = ({ setState, payload={} })=>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                ...payload,
                displayToast: false,
            }
        })
    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error:e
            }
        })
    }
}

const getPatientInitAction = async({state,setState}) =>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                isLoading : true,
            }
        })

        const response = await fetch(`${BASE_URL}/api/dashboard/users/patientsByStatus?status=${INVITE_PATIENT_STATUS.ACTIVE}`,{
            method : "GET",
            headers :{
                "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
            }
        })

        const body = await response.json();

        setState((prev)=>{
            return {
                ...prev,
                patients:{
                    ...prev.patients,
                    ACTIVE : body,
                },
                status : INVITE_PATIENT_STATUS.ACTIVE,
                isLoading : false,
            }
        })

    }catch(e)
    {
        setState((prev)=>{
            return {...prev,error :e,isLoading : false}
        })
    }
}

const setPatientPageStatus = async({state, setState, payload}) =>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                isLoading : true,
            }
        })
        const response = await fetch(`${BASE_URL}/api/dashboard/users/patientsByStatus?status=${payload}`,{
            method : "GET",
            headers :{
                "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
            }
        })

        const body = await response.json();

        setState((prev)=>{
            return {
                ...prev,
                patients:{
                    ...prev.patients,
                    [payload] : body,
                },
                status : payload,isLoading : false,
                filterText: ""
            }
        })

    }catch(e)
    {
        setState((prev)=>{
            return {...prev,
                error :e,
                filterText: "",
                isLoading : false
            }
        })
    }
}

const getResendPatientinviteAction = async({state, setState, payload})=>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                isLoading : true,
            }
        })

        await fetch(`${BASE_URL}/api/dashboard/users/resendPatientInvite?id=${payload}`,{
            method : "PUT",
            headers :{
                "authorization" : "Bearer " + window?.localStorage?.getItem("Authorization"),
            }
        });

        setState((prev)=>{
            return {
                ...prev,
                isLoading : false,
                displayToast:true,
                toastMessage : "Invite Sent Successfully",
                type:'success'
            }
        });

        setPatientPageStatus({state, setState, payload:INVITE_PATIENT_STATUS.PENDING})

    }
    catch(e)
    {

        setState((prev)=>{
            return {...prev,error :e,isLoading : false, displayToast:true, type:"failed", toastMessage : "Invite Failed" }
        })
    }
}

const getRowItemClickAction = async({state, setState, payload, navigate})=>{
    try{
        const {status}  = state;
        if(payload &&(!status || status == "ACTIVE"))
        {
            navigate(`/patients/${payload}`)
        }
    }
    catch(e)
    {
        setState((prev)=>{
            return {
                ...prev,
                error : e
            }
        })
    }
}

const getFilteredListAction = ({setState, payload})=>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                filterText: payload,
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error:e
            }
        })
    }
}

const getToastHandleButton = ({state, setState, payload})=>{
    try{
        setState((prev)=>{
            return {
                ...prev,
                displayToast:true,
                ...payload
            }
        })
    }
    catch(e){
        setState((prev)=>{
            return {
                ...prev,
                error:e
            }
        })
    }
}

export default {
    INIT_ACTION : getPatientInitAction,
    CHANGE_PATIENT_PAGE_STATUS : setPatientPageStatus,
    RESEND_PATIENT_INVITE_ACTION : getResendPatientinviteAction,
    TABLE_ROW_CLICK : getRowItemClickAction,
    CLOSE_TOAST_STATUS: getToastStatusCloseUpdate,
    TABLE_FILTER_ACTION : getFilteredListAction,
    OPEN_TOAST_ACTION : getToastHandleButton
}