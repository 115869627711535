import styles from "./pageHeaderContext.module.css"

const PageHeaderContext = ({practiceName}) => {

    return (
        
            <div className={styles.pageHeaderContext}>
                <div className={styles.subHeading}><div className={styles.subHeadingPractice}>Practice Management </div>/ {practiceName}</div>
            </div>

    )
}

export default PageHeaderContext;