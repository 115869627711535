import React, { useState } from "react";
import {map} from "lodash";

import styles from "./table.module.css"
import { getTextInitials } from "../../../../../../helpers/TextManipulations.helpers";
import Pagination from "../../../pagination";

const Table = ({patientList=[]}) => {

    const [currentPagination, setCurrentPagination] = useState(1);

    const handleShowPreviousListItems = ()=>{
        if(currentPagination==1){
            return;
        }
        setCurrentPagination(currentPagination-1);
    }

    const handleShowNextListItems = ()=>{
        if((patientList.length<=(10*currentPagination))){
            return
        }
        setCurrentPagination(currentPagination+1);
    }

    const paginatedList = patientList.slice(10*(currentPagination-1),Math.min(patientList.length,10*(currentPagination)));

    return (
        <>
        <div className={styles.practiceListContainer}>
            <div className={styles.tableHeader}>
                <div className={styles.tableColumn}>NAME<img src={require("../../../../../../../assets/images/sort-icon.svg").default} className={styles.searchLogo}></img></div>
                <div className={styles.tableColumn}>EMAIL</div>
                <div className={styles.tableColumn}>PHONE NUMBER</div>
            </div>
            <div className={styles.tableColumnContainer}>

                {map(paginatedList,(patient)=>{
                    const { firstName="", lastName="" ,email="" , phoneNumber=""} = patient || {};
                    const nameInitials = getTextInitials(firstName+" "+lastName);

                    return (
                        <div className={styles.tableColumnData}>
                        <div className={styles.tableColumnDataContainer}>
                            <div className={styles.avatar}>
                                <div className={styles.avatarData}>
                                    {nameInitials}
                                </div>
                            </div>
                            <div className={styles.nameAndUrlContainer}>
                                <div className={styles.practiceName}>
                                    {firstName+" "+lastName}
                                </div>
                            </div>
                        </div>
    
                        <div className={styles.tableColumnDataContainer}>
                            <div className={styles.nameAndUrlContainer}>
                                <div className={styles.patientInfoStyle}>
                                    {email}
                                </div>
                            </div>
                        </div>
    
                        <div className={styles.tableColumnDataContainer}>
                            <div className={styles.nameAndUrlContainer}>
                                <div className={styles.patientInfoStyle}>
                                    {phoneNumber}
                                </div>
                            </div>
                        </div>
                    </div>    
                    )
                })}

            </div>
        </div>
        { paginatedList?.length >0 &&
        <div className={styles.paginationContainer}>
            <Pagination
                handleShowPreviousListItems={handleShowPreviousListItems} 
                handleShowNextListItems={handleShowNextListItems}
                currentPage={currentPagination}
            />
        </div>
        }
        </>

    )
}

export default Table;
