import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import MainContainer from "./containers/mainContainer";
import withActionState from "../../hooks/withActionState";
import actions from "./commonHeader.actions";
import actionTypes from "./commonHeader.actionTypes";

import styles from "./commonHeader.module.css";
import { getHeaderOptions } from "./commonHeader.options";
import { useNavigate } from "react-router-dom";

const PracticeManagementCommonHeader = (props) =>{

    const [displayDropdown, setDisplayDropdown] = useState(false);

    const navigate = useNavigate();

    const [subscriber, setSubscriber] = useState({
        callback:null
    });
    
    const getDropdownToggle = useCallback((payload) =>{
        setDisplayDropdown(payload || !displayDropdown);
    },[])

    const handleSubscriberUpdate = useCallback((callback)=>{
        setSubscriber((prev)=>({
            callback
        }))
    },[setSubscriber])
    
    const getLogoutAction = useCallback(() =>{
        window?.localStorage?.clear();
        navigate("/login")
    },[navigate]);

    const getSettingsAction = useCallback(() =>{
        navigate("/settings");
    },[navigate]);

    const handlePageUpdateAction =useCallback((callback)=> (...props)=>{
        if(subscriber?.callback){
            subscriber?.callback(function(){
                callback(...props)
            });
            return;
        }
        callback(...props);
    },[subscriber?.callback]);

    const options = getHeaderOptions({logOutAction : getLogoutAction, logOutClass : styles.logOutClass, settingsAction : getSettingsAction, settingsClass : styles.settingsClass, handlePageUpdateAction})

    const renderChildren = useCallback(()=>{
        return React.Children.map(props?.children,(child)=>{
            return React.cloneElement(child,{
                handleSubscriberUpdate
            })
        })
    },[props?.children])

    return (
        <div onClick={()=>getDropdownToggle(false)}>
            <div className={styles.container}>
                <MainContainer options={options} displayDropdown={displayDropdown} getDropdownToggle={getDropdownToggle} getLogoutAction={getLogoutAction} getSettingsAction={getSettingsAction} />
            </div>
            {renderChildren()}
            {/* {props?.children} */}
        </div>
    )
}

export default PracticeManagementCommonHeader;